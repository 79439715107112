<template>
   <v-row no-gutters>
      <v-progress-linear indeterminate :color="`${systemDetails.themecolor}`" v-if="historyListLoading"></v-progress-linear>
      <v-col cols="12">
        <v-card class="mt-1" flat>
          <v-timeline dense class="subtitle-1">
            <template v-for="(val, index) in historyObj">
              <v-timeline-item small class="timelineItemCompressed" :key="index">
                <span v-if="val.isOnlyContent">{{ val.content }}  on {{$formatter.formatDate(val.date, 'DD.MM.YYYYTHH.mm.ss', `${userDetails.dateformat}  HH:mm`)}}</span>
                <span v-else>
                  {{ val.updatedProperty }} is changed from <span :style="{ color: val.oldVal.color }"><b>{{ val.oldVal.name }}</b></span> to <span :style="{ color: val.newVal.color }"><b>{{ val.newVal.name }}</b></span> on {{$formatter.formatDate(val.date, 'DD.MM.YYYYTHH.mm.ss', `${userDetails.dateformat}  HH:mm`)}} <span> By <strong> <span style="color: rgb(107, 107, 202); font-weight: bold;">{{val.username}}</span> </strong></span>
                </span>
              </v-timeline-item>
            </template>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['ticketId'],
  data () {
    return {
      historyObj: {},
      historyListLoading: false
    }
  },
  computed: {
    ...mapGetters(['getListOfTicketPriorities', 'getListOfTicketStatus', 'getListOfTicketCategory', 'getListOfGroups', 'userDetails', 'systemDetails', 'getUserGroups', 'getUsers'])
  },
  mounted () {
    this.getHistory()
  },
  methods: {
    getHistory () {
      this.historyListLoading = true
      this.$api.execute('get', `tickets/get_ticket_timeline?ticketId=${this.ticketId}`).then(({ data }) => {
        if (data.histories) {
          this.historyObj = []
          for (const val of data.histories) {
            if (val.content != null) {
              const history = {
                date: this.$formatter.formatDate(val.date, 'DD.MM.YYYYTHH.mm.ss', `${this.userDetails.dateformat}  HH:mm`),
                isOnlyContent: true,
                content: val.content,
                username: val.username
              }
              this.historyObj.push(history)
            } else if (val.priorityid || val.oldpriorityid) {
              const oldPriorityObj = this.getListOfTicketPriorities.find(priority => priority._id === val.oldpriorityid)
              const newPriorityObj = this.getListOfTicketPriorities.find(priority => priority._id === val.priorityid)
              const history = {
                date: this.$formatter.formatDate(val.date, 'DD.MM.YYYYTHH.mm.ss', `${this.userDetails.dateformat}  HH:mm`),
                updatedProperty: 'Priority',
                oldVal: {
                  name: oldPriorityObj ? oldPriorityObj.name : '--',
                  color: oldPriorityObj ? oldPriorityObj.color : '--'
                },
                newVal: {
                  name: newPriorityObj ? newPriorityObj.name : '--',
                  color: newPriorityObj ? newPriorityObj.color : '--'
                },
                username: val.username
              }
              this.historyObj.push(history)
            } else if (val.statusid || val.oldstatusid) {
              const oldStatusObj = val.oldstatusid ? this.getListOfTicketStatus.find(status => status._id === val.oldstatusid) : null
              const newStatusObj = val.statusid ? this.getListOfTicketStatus.find(status => status._id === val.statusid) : null
              const history = {
                date: this.$formatter.formatDate(val.date, 'DD.MM.YYYYTHH.mm.ss', `${this.userDetails.dateformat}  HH:mm`),
                updatedProperty: 'Status',
                oldVal: {
                  name: oldStatusObj ? oldStatusObj.name : '--',
                  color: oldStatusObj ? oldStatusObj.color : '--'
                },
                newVal: {
                  name: newStatusObj ? newStatusObj.name : '--',
                  color: newStatusObj ? newStatusObj.color : '--'
                },
                username: val.username
              }
              this.historyObj.push(history)
            } else if (val.category || val.oldcategory) {
              const oldCategoryObj = val.oldcategory ? this.getListOfTicketCategory.find(category => category._id === val.oldcategory) : null
              const newCategoryObj = val.category ? this.getListOfTicketCategory.find(category => category._id === val.category) : null
              const history = {
                date: this.$formatter.formatDate(val.date, 'DD.MM.YYYYTHH.mm.ss', `${this.userDetails.dateformat}  HH:mm`),
                updatedProperty: 'Category',
                oldVal: {
                  name: oldCategoryObj ? oldCategoryObj.name : '--'
                },
                newVal: {
                  name: newCategoryObj ? newCategoryObj.name : '--'
                },
                username: val.username
              }
              this.historyObj.push(history)
            } else if ((val.assignedto || val.oldassignedto || val.oldassignedtogroup || val.assignedtogroup)) {
              let name = ''
              let newname = ''
              let oldAssignedToObj = val.oldassignedto ? this.getUsers.find(x => x._id === val.oldassignedto) : null
              if (oldAssignedToObj == null) {
                oldAssignedToObj = val.oldassignedtogroup ? this.getUserGroups.find(x => x._id === val.oldassignedtogroup) : null
                name = oldAssignedToObj ? oldAssignedToObj.name : '--'
              } else {
                name = [oldAssignedToObj.firstname, oldAssignedToObj.lastname].filter(x => x).join(' ')
              }
              let newAssignedToObj = val.assignedto ? this.getUsers.find(x => x._id === val.assignedto) : null
              if (newAssignedToObj === null) {
                newAssignedToObj = val.assignedtogroup ? this.getUserGroups.find(x => x._id === val.assignedtogroup) : null
                newname = newAssignedToObj.name
              } else {
                newname = [newAssignedToObj.firstname, newAssignedToObj.lastname].filter(x => x).join(' ')
              }
              const history = {
                date: this.$formatter.formatDate(val.date, 'DD.MM.YYYYTHH.mm.ss', `${this.userDetails.dateformat}  HH:mm`),
                updatedProperty: 'Assigned to',
                oldVal: {
                  name: oldAssignedToObj ? name : '--'
                },
                newVal: {
                  name: newAssignedToObj ? newname : '--'
                },
                username: val.username
              }
              this.historyObj.push(history)
            }
            // else if(val.assignedtogroup || val.oldassignedtogroup){
            //   let oldAssignedToGroupObj = val.oldassignedtogroup ? this.getUserGroups.find(x => x._id == val.oldassignedtogroup) : null
            //   let newAssignedToGroupObj = val.assignedtogroup ? this.getUserGroups.find(x => x._id == val.assignedtogroup) : null
            //   let history = {
            //     date : this.$formatter.formatDate(val.date, 'DD.MM.YYYYTHH.mm.ss', `${this.userDetails.dateformat}  HH:mm`),
            //     updatedProperty: 'Assigned to',
            //     oldVal : {
            //       name: oldAssignedToGroupObj ? oldAssignedToGroupObj.name : '--'
            //     },
            //     newVal : {
            //       name: newAssignedToGroupObj ? newAssignedToGroupObj.name : '--'
            //     },
            //     username:val.username
            //   }
            //   this.historyObj.push(history)
            // }
          }
        }
        this.historyListLoading = false
      })
    }
  }
}
</script>
